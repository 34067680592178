var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"app"}},[_c('div',[_c('b-navbar',{attrs:{"fixed":"top","toggleable":"sm","type":"light","variant":"faded"}},[_c('b-navbar-brand',[_c('router-link',{attrs:{"to":"/nakupniKosik","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-link d-block d-sm-none"},[_c('a',{class:{ 'nav-link': true, active: isExactActive },attrs:{"href":href,"title":_vm.countPiece + ' ks za ' + _vm.sumPrice + 'Kč'},on:{"click":navigate}},[_c('i',{staticClass:"fa"},[_vm._v("")]),_c('span',{staticClass:"badge badge-warning",attrs:{"id":"lblCartCount"}},[_vm._v(" "+_vm._s(_vm.countPiece)+" ")])])])]}}])})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"offset-lg-1 offset-xl-2"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-link': true }},[_c('a',{class:{ 'nav-link': true, active: isExactActive },attrs:{"href":href},on:{"click":navigate}},[_vm._v("S knížkou do života")])])]}}])}),_c('router-link',{attrs:{"to":"/knihy","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-link"},[_c('a',{class:{ 'nav-link': true, active: isExactActive },attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Knihy")])])]}}])}),_c('router-link',{attrs:{"to":"/pomucky","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                'nav-link': true,
                'router-link-exact-active': isExactActive,
              }},[_c('a',{class:{ 'nav-link': true, active: isExactActive },attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-life-ring"}),_vm._v(" Pomůcky")])])]}}])}),_c('router-link',{attrs:{"to":"/nakupniKosik","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var route = ref.route;
              var navigate = ref.navigate;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                'nav-link d-none d-sm-block': true,
                'router-link-exact-active': isExactActive,
              }},[_c('a',{class:{ 'nav-link': true, active: isExactActive },attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fa"},[_vm._v("")]),_vm._v(" Košík "),_c('span',{staticClass:"badge badge-warning bg-danger"},[_vm._v(_vm._s(_vm.sumPrice)+"Kč")])])])]}}])})],1)],1)],1)],1),_c('router-view',{staticClass:"offset-lg-1 offset-xl-2",on:{"add-to-basked":function($event){return _vm.addToBasked($event)},"update-basked":function($event){return _vm.updateBasked($event)},"clean-basked":function($event){return _vm.cleanBasket()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }